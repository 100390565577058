import React, { useEffect, useState } from "react";
import classNames from 'classnames';

import HeaderImage from '../../components/imageHeader';

import { graphql } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl";

import ReactMarkdown from 'react-markdown';

import Layout from "../../components/layout";

import '../../styles/index.css';
import styles from '../../styles/consulting.module.css';
import styleser from '../../styles/servico.module.css';

const AvaliacaoEmpresasPage = ({ data }) => {
    const intl = useIntl();

    const [avaEmpresas, setAvaEmpresas] = useState({})

    useEffect(() => {
        data.allStrapiServico.edges.map((item) => {
            if (item.node.tituloPt === "Valuation - Avaliação de Empresas") {
                setAvaEmpresas(item.node)
            }
        })
    }, [])

    const titulo = intl.locale === 'en' ? avaEmpresas.tituloEn : avaEmpresas.tituloPt;
    const texto = intl.locale === 'en' ? avaEmpresas.textoEn : avaEmpresas.textoPt;

    return (
        <Layout titlePage={titulo} renderGTM={true}>

            <HeaderImage title={titulo} />

            <div className={classNames("container", styles.consultingContainer)}>
                <main style={{'padding': '40px 0 0'}}>

                    <ReactMarkdown
                        className={styleser.boxServicoImg}
                        source={texto}
                        escapeHtml={false}
                        transformImageUri={
                          uri => uri.startsWith('http') ?
                            uri : `${process.env.GATSBY_API_URL}${uri}`
                        }
                    />

                    {/* Link do Whatsapp */}
                    <div className="whatsAppLogo">
                        <a href="https://wa.me/5511976282854" title="Whatsapp" className='wppFixed' >
                            <img src="/wpp.svg" alt="" />
                        </a>
                    </div>
                </main>

            </div>

            {/* Google tag (gtag.js) event */}
            <script>
                gtag('event', 'contacte_nos', {
                // <event_parameters>
                });
            </script>
        </Layout>
    )
}

export const query = graphql`
query{
    allStrapiServico {
        edges {
            node {
                tituloPt
                textoPt
                tituloEn
                textoEn
            }
        }
    }
}
`

export default AvaliacaoEmpresasPage
